@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;400&display=swap");
@import url("https://assets.calendly.com/assets/external/widget.css");
html {
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.masthead {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "Noto Serif", serif;
}
.masthead div {
  display: flex;
}
.masthead div:nth-child(1) {
  width: 100%;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding: 4rem;
}
.masthead div:nth-child(2) {
  display: none;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
}
.masthead .masthead-logo {
  margin: 0 0 3rem 0;
}
.masthead .masthead-portrait {
  height: 110%;
  width: auto;
  image-rendering: auto;
}
.masthead .btn-scroll-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.masthead .btn-scroll-mobile {
  border: 2px solid #000;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #000;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  bottom: 50px;
  position: absolute;
  text-transform: uppercase;
}
.masthead .btn-scroll-mobile svg {
  width: 100px;
  height: 100px;
}
.masthead .btn-scroll {
  display: none;
  position: absolute;
  z-index: 2;
  bottom: 60px;
  right: 2rem;
  background-color: transparent;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  opacity: 0;
  transition: 0.5s;
}
.masthead .btn-scroll:hover {
  opacity: 1;
}
.masthead .btn-scroll svg {
  width: 160px;
  height: 160px;
}
.masthead .animate-scroll {
  animation-name: animateScroll;
  animation-duration: 2800ms;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
}
@keyframes animateScroll {
  0% {
    bottom: 60px;
    opacity: 0;
  }
  50% {
    bottom: 20px;
    opacity: 1;
  }
  75% {
    bottom: 20px;
    opacity: 1;
  }
  100% {
    bottom: 60px;
    opacity: 0;
  }
}
.masthead .masthead-intro {
  font-size: 27px;
  font-weight: 400;
  text-align: center;
}

.home-section {
  display: flex;
  padding: 4rem;
  flex-direction: column;
  font-weight: 300;
  font-size: 20px;
}
.home-section p {
  margin: 0.5rem 0;
}
.home-section h1 {
  font-weight: 300;
  font-size: 40px;
}
.home-section h2 {
  font-weight: 500;
  margin: 14px 0;
  font-size: 24px;
}

.contact-section {
  display: flex;
  padding: 4rem;
  flex-direction: column;
  font-weight: 300;
  font-size: 18px;
}
.contact-section h1 {
  font-weight: 300;
  font-size: 50px;
}
.contact-section p {
  font-size: 18px;
}
.contact-section div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-section a {
  transition: 0.5s;
}
.contact-section a:link, .contact-section a:active, .contact-section a:visited {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}
.contact-section a:hover {
  border-bottom: 1px solid #000;
}
.contact-section .social-media {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  padding: 1rem 0;
}
.contact-section .social-media a {
  text-decoration: none;
  color: #000;
  border: 0;
}
.contact-section .social-media svg {
  width: 30px;
  height: 30px;
}

.accordion-button {
  padding: 0;
  border: 0 !important;
  outline: none !important;
  color: rgba(112, 112, 112, 0.8352941176);
}
.accordion-button:focus {
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
  color: #000;
}

.accordion-button:not(.collapsed) {
  color: black;
  background: transparent !important;
  outline: none !important;
  border: 0 !important;
}

.accordion-trigger-nested {
  font-size: 20px;
}

.accordion-body {
  font-size: 18px;
  padding: 12px 0;
}

.accordion-flush .accordion-item {
  border-color: #eee;
}

.content-row-r {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content-row-l {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btnBook {
  background: transparent;
  border: 0;
  padding: 1rem 0;
}

.terms-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
.terms-wrapper .home-section {
  padding: 2rem;
}
.terms-wrapper .home-section a {
  transition: 0.5s;
  padding: 10px 0;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.terms-wrapper .home-section a:link, .terms-wrapper .home-section a:active, .terms-wrapper .home-section a:visited {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}
.terms-wrapper .home-section a:hover {
  border-bottom: 1px solid #000;
}

.terms-logo {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.terms-link {
  width: -moz-fit-content;
  width: fit-content;
}

.contact-book-wrapper {
  justify-content: flex-start !important;
  padding: 0.5rem 0;
}
.contact-book-wrapper div {
  display: block;
}

@media only screen and (min-width: 768px) {
  .masthead div:nth-child(1) {
    width: 62%;
    align-items: flex-start;
  }
  .masthead div:nth-child(1) .masthead-intro {
    align-self: center;
    text-align: left;
  }
  .masthead div:nth-child(2) {
    height: 100vh;
    width: 38%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
  }
  .masthead .btn-scroll {
    display: block;
  }
  .masthead .btn-scroll-mobile {
    display: none;
  }
  .masthead .masthead-portrait {
    height: 115%;
    margin-top: -30%;
    margin-right: -45%;
  }
  .contact-section {
    flex-direction: row;
  }
  .contact-section div:nth-child(1) {
    width: 60%;
  }
  .contact-section div:nth-child(2) {
    width: 40%;
  }
  .content-row-r {
    justify-content: flex-end;
  }
  .content-row-l {
    justify-content: flex-start;
  }
  .terms-wrapper {
    flex-direction: row;
    width: 100%;
  }
  .terms-wrapper div:nth-child(1) {
    width: 60%;
  }
  .terms-wrapper div:nth-child(2) {
    width: 40%;
  }
  .terms-wrapper .home-section {
    padding: 4rem;
  }
  .terms-logo {
    justify-content: flex-end;
    position: fixed;
    right: 4rem;
    top: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .masthead .masthead-portrait {
    height: 130%;
    margin-top: -22%;
    margin-right: -33%;
  }
}
@media only screen and (min-width: 1200px) {
  .masthead .masthead-portrait {
    height: 130%;
    margin-top: -22%;
    margin-right: -22%;
  }
}/*# sourceMappingURL=App.css.map */