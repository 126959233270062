@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;400&display=swap');
@import url('https://assets.calendly.com/assets/external/widget.css');

html {
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.masthead {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'Noto Serif', serif;

    div {
        display: flex;
    }

    div:nth-child(1) {
        width: 100%;
        align-items: center;
        flex-direction: column;
        text-align: left;
        padding: 4rem;
    }
    div:nth-child(2) {
        display: none;
        justify-content: flex-end;
        align-items: flex-end;
        overflow: hidden;
        position: relative;
    }

    .masthead-logo {
        margin: 0 0 3rem 0;
    }

    .masthead-portrait {
        height: 110%;
        width: auto;
        image-rendering: auto;
    }
.btn-scroll-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.btn-scroll-mobile {
    border: 2px solid #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #000;
    background-color: transparent;
    border: 0;
    font-size: 24px;
    bottom: 50px;
    position: absolute;
    text-transform: uppercase;

    svg {
        width: 100px;
        height: 100px;
    }
}

    .btn-scroll {
        display: none;
        position:absolute;
        z-index: 2;
        bottom: 60px;
        right: 2rem;
        background-color: transparent;
        color: #fff;
        border: 0;
        text-transform: uppercase;
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        opacity: 0;
        transition: 0.5s;

        &:hover {
            opacity: 1;
        }

        svg {
            width: 160px;
            height: 160px;
        }
    }

    .animate-scroll {
        animation-name: animateScroll;
        animation-duration: 2800ms;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-timing-function: ease-in-out;
        animation-delay: 1s;
      }

      @keyframes animateScroll {
        0%   {
            bottom: 60px;
            opacity: 0;
        }

        50%   {
            bottom: 20px;
            opacity: 1;
        }

        
        75% {
            bottom: 20px;
            opacity: 1;
        }

        100% {
            bottom: 60px;
            opacity: 0;
        }
      }

    .masthead-intro {
        font-size: 27px;
        font-weight: 400;
        text-align: center;
    }
}

.home-section {
    display: flex;
    padding: 4rem;
    flex-direction: column;
    font-weight: 300;
    font-size: 20px;

    p {
        margin: 0.5rem 0;
    }

    h1 {
        font-weight: 300;
        font-size: 40px;
    }

    h2 {
        font-weight: 500;
        margin: 14px 0;
        font-size: 24px;
    }
}

.contact-section {
    display: flex;
    padding: 4rem;
    flex-direction: column;
    font-weight: 300;
    font-size: 18px;

    h1 {
        font-weight: 300;
        font-size: 50px;
    }

    p {
        font-size: 18px;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    a {
        transition: 0.5s;
    }
    a:link, a:active, a:visited {
        color: #000;
        text-decoration: none;
        border-bottom: 1px solid #fff;
    }

    a:hover {
        border-bottom: 1px solid #000;
    }

    .social-media {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 10px;
        padding: 1rem 0;

        a {
            text-decoration: none;
            color: #000;
            border: 0;
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }

}

.accordion-button {
    padding: 0;
    border: 0 !important;
    outline: none !important;
    color: #707070d5;

    &:focus {
        background-color: transparent !important;
        border: 0;
        box-shadow: none;
        color: #000;
    }
}

.accordion-button:not(.collapsed) {
    color: black;
    background: transparent !important;
    outline: none !important;
    border: 0 !important;
}

.accordion-trigger-nested {
    font-size: 20px;
}

.accordion-body {
    font-size: 18px;
    padding: 12px 0;
}

.accordion-flush .accordion-item {
    border-color: #eee;
}

.content-row-r {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.content-row-l {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btnBook {
    background: transparent;
    border: 0;
    padding: 1rem 0;
}

.terms-wrapper  {
    display: flex;
    flex-direction: column-reverse;

    .home-section {
        padding: 2rem;

        a {
            transition: 0.5s;
            padding: 10px 0;
            display: flex;
            align-items: center;
            width:fit-content;
        }
        a:link, a:active, a:visited {
            color: #000;
            text-decoration: none;
            border-bottom: 1px solid #fff;
        }
    
        a:hover {
            border-bottom: 1px solid #000;
        }
    }
}

.terms-logo {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2rem;
 }

 .terms-link {
    width: fit-content;
 }

 .contact-book-wrapper {
    justify-content: flex-start !important;
    padding: 0.5rem 0;
    div {
        display: block;
    }
 }

@media only screen and (min-width: 768px) {
    .masthead {
        div:nth-child(1) {
            width: 62%;
            align-items: flex-start;

            .masthead-intro {
                align-self: center;
                text-align: left;
            }
        }
        div:nth-child(2) {
            height: 100vh;
            width: 38%;
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: flex-start;
         }
         .btn-scroll {
            display: block;
         }
         .btn-scroll-mobile {
            display: none;
         }

         .masthead-portrait {
            height: 115%;
            margin-top: -30%;
            margin-right: -45%;
        }
    }

    .contact-section {
        flex-direction: row;

        div:nth-child(1) {
            width: 60%;
        }
    
        div:nth-child(2) {
            width: 40%;
        }
    }

    .content-row-r {
        justify-content: flex-end;
    }

    .content-row-l {
        
        justify-content: flex-start;
    }

    .terms-wrapper  {
        flex-direction: row;
        width: 100%;

        div:nth-child(1) {
            width: 60%;
        }

        div:nth-child(2) {
            width: 40%;
        }

        .home-section {
            padding: 4rem;
        }
    }

    .terms-logo {
        justify-content: flex-end;
        position: fixed;
        right: 4rem;
        top: 2rem;
     }
 }

 

@media only screen and (min-width: 1024px) {
    
    .masthead {
        .masthead-portrait {
            height: 130%;
            margin-top: -22%;
            margin-right: -33%;
        }
    }
    
 }

 @media only screen and (min-width: 1200px) {
    
    .masthead {
        .masthead-portrait {
            height: 130%;
            margin-top: -22%;
            margin-right: -22%;
        }
    }
 }